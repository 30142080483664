import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import Hero from '../components/Hero';
import Hero2 from '../components/Hero2';
import ClientLogos from '../components/about/ClientLogos';
import GraySection from '../components/GraySection';
// import RightPeople from '../components/home/RightPeople';
import Structures from '../components/home/Structures';
// import Approach from '../components/home/Approach';
import Reviews from '../components/home/Reviews';
import Community from '../components/home/Community';
import Blog from '../components/home/Blog';
import CtaBox from '../components/CtaBox';
import VideoSection from '../components/VideoSection';
import CommunityLogos from '../components/home/CommunityLogos';
import InteractiveProcess from '../components/InteractiveProcess';
import StatsSection from '../components/home/StatsSeection';

import '../css/home/homepage.scss';

export default function IndexPage({ data, location }) {
  const { title, text1, rotatingTexts2, heroImg } =
    data.sanityHomepage.heroHome;
  const { hitusup, blogSec } = data.sanityHomepage;
  const { seo } = data.sanityHomepage;

  return (
    <>
      <SEO
        title={seo.title && seo.title}
        description={seo.description && seo.description}
        image={seo.ogImage && seo.ogImage.asset.url}
        location={location}
      />

      <Layout companies>
        {/* <Hero
          forHome
          title={title}
          para={heroText}
          heroImg={heroImg.asset.url}
        /> */}
        <Hero2
          forHome
          title={title}
          textgrp1={text1}
          textgrp2={rotatingTexts2}
          heroImg={heroImg.asset.url}
          companies
        />
        <ClientLogos noTopPad />
        <StatsSection />
        <GraySection navTag="For Companies" id="for-companies">
          <div className="wrapper center">
            <VideoSection video="https://res.cloudinary.com/de6m3kn4q/video/upload/f_auto:video,q_auto/v1/nineDots/hiring" />
          </div>
          <CommunityLogos />
          <InteractiveProcess />
          <Structures />
          {/* <RightPeople />
          <Approach /> */}
          <Reviews />
        </GraySection>
        <GraySection type2 navTag="For Candidates" id="for-candidates">
          {/* <Connection navTag={miscTitles.candidatesTitle} />
          <CandidateReviews />
          <div className="home-specialise">
            <Specialize secTitle={miscTitles.categoryTitle} />
            <FeaturedJobs />
          </div> */}
          <Community hitusup={hitusup} />
        </GraySection>
        <Blog title={blogSec.heading} para={blogSec.subheading} />
        <CtaBox />
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityHomepage {
      heroHome {
        title
        text1
        rotatingTexts2
        heroImg {
          asset {
            url
          }
        }
      }
      hitusup {
        heading
        subheading
      }
      blogSec {
        heading
        subheading
      }
      seo {
        title
        description
        ogImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
